export const msalConfig = {
  auth: {
    //Name: "MyDesk Tenant Admin",
    clientId: "b2cc997d-f0b3-419f-b232-591296a3ac9f",
    authority: "https://login.microsoftonline.com/common/", //7aee26e5-5628-41d5-b836-5a8e35dbd585 for single tenant// This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    
    redirectUri: "https://admin.mydesk-test.dk/",
    //redirectUri: "https://admin.mydesk.dk/",
    //redirectUri: "https://localhost:44337/",
    resource:"https://graph.microsoft.com"
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const tokenScope ="api://b2cc997d-f0b3-419f-b232-591296a3ac9f/user_impersonation";
export const graphScope= "https://graph.microsoft.com/CrossTenantInformation.ReadBasic.All";//"https://graph.microsoft.com/.default"; 

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [tokenScope]
};

export const tokenRequest = {
  scopes: [tokenScope],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token,
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};
